import $ from 'jquery';
import { breakpointMD } from '../_variables';

export default class BtnFixedEntry {
  constructor(props) {
    this.ele = document.querySelector(props.target);
  }

  init() {
    if (!this.ele) {
      return;
    }

    const $target = $(this.ele);

    $(window).on('scroll', function () {
      const scrollTop = $(this).scrollTop();

      if ($(window).innerWidth() <= breakpointMD) {
        $target.removeAttr('style');
        return;
      }

      if (scrollTop > 0) {
        $target.not(':animated').stop().fadeIn(200);
      } else {
        $target.not(':animated').stop().fadeOut(200);
      }
    });
  }
}
