import $ from 'jquery';

export default class instagramFactory {
  /**
   * 
   * @param {ele, number} props
   * ele : instagram feed element
   * numberOfPhoto : number of photo
   */
  constructor(props) {
    this.ele = document.querySelector(props.target);
    this.numberOfPhoto = props.numberOfPhoto;
  }

  init() {
    if (this.ele) {
      var $instagram_feed = this.ele;
      var $numberOfPhoto = this.numberOfPhoto;

      function insertInstaImages(cb) {

        ajax('/assets/php/instagram_factory.php?insta=photo', { insta: 'photo' }, function (data) {
          console.log("load_instagram_factory");
          var html = "";
          for (var i = 0, l = $numberOfPhoto; i < l; i++) {
            html += `<a href="${data[i].link}" target="_blank"><figure><img src="${data[i].photo}"></figure></a>`;
          }
          $instagram_feed.innerHTML = html;
          if (cb) {
            cb();
          }

        });
      }
      function ajax(url, prm, cb) {
        $.ajax({
          type: 'GET',
          url: url,
          data: prm,
          success: function (data) {
            cb(JSON.parse(data));
          },
          complete: function (data) {

          },
          error: function () {
            console.log('Error');
          }
        });
      }
      insertInstaImages();
    }
  }
}

