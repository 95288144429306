import $ from 'jquery';
import Slick from 'slick-carousel';
import { breakpointMD } from '../_variables';

export default class RecruitIndex {
  constructor(props) {
    this.ele = document.querySelector(props.target);
  }

  init() {
    if (!this.ele) {
      return;
    }

    this.mvSlider();
    this.initMvCoverImage();
    this.bgParallax();
  }

  mvSlider() {
    const $target = $('.js-fade-slider');
    const $content = $('.js-mv-box-content');

    if (!$target.length) {
      return;
    }

    $target.on('init', () => {
      $content.addClass('is-animated');
    });

    const $slider = $target.slick({
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      fade: true,
      dots: false,
      arrows: false,
      swipe: false,
      touchMove: false,
      pauseOnFocus: false,
      pauseOnHover: false,
      rows: 0,
    });
  }

  initMvCoverImage() {
    const $target = $('.js-mv-cover-image');

    if (!$target.length) {
      return;
    }

    const resizeCoverImage = ($wrapper) => {
      const $boxIcon = $wrapper.find('.box-icon');
      const $img = $wrapper.find('.image');
      const wrapWidth = $wrapper.width();
      const wrapHeight = $wrapper.height();

      const imgWidth = $img.filter(':visible').width();
      const imgHeight = $img.filter(':visible').height();
      const wratio = wrapWidth / imgWidth;
      const hratio = wrapHeight / imgHeight;

      if (wratio > hratio) {
        let top = '50%';
        let tsForm = 'translateY(-50%)';

        if (window.orientation === 90 || window.orientation === -90) {
          top = 0;
          tsForm = 'none';
        }

        $img.css({
          top: top,
          left: 0,
          width: '100%',
          height: 'auto',
          transform: tsForm,
        });
        $boxIcon.css({
          top: top,
          left: 0,
          width: imgWidth,
          height: imgHeight,
          transform: tsForm,
        });
      } else {
        $img.css({
          top: 0,
          left: '50%',
          width: 'auto',
          height: '100%',
          transform: 'translateX(-50%)',
        });
        $boxIcon.css({
          top: 0,
          left: '50%',
          width: imgWidth,
          height: imgHeight,
          transform: 'translateX(-50%)',
        });
      }
    };

    $target.find('.item').each((index, el) => {
      const $wrapper = $(el);
      let resizeTimer;

      // Resize event
      $(window).on('resize', () => {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          resizeCoverImage($wrapper);
        }, 200);
      });

      $(window).on('load', resizeCoverImage($wrapper));

      resizeCoverImage($wrapper);
    });
  }

  bgParallax() {
    const $target = $('.js-recruit-parallax');

    if (!$target.length) {
      return;
    }

    const $item = $target.children('.js-parallax-item');
    
    $(window).on('scroll', function () {
      if ($(window).innerWidth() <= breakpointMD) {
        $item.removeAttr('style');
        return;
      }

      const offset = $target.offset().top;
      const scrollTop = $(window).scrollTop();
      const posX = (Math.floor((scrollTop - offset) / 5));

      $item.css({ transform: `translateZ(0) translateY(${posX}px)` });
    });
  }
}
