import $ from 'jquery';
import {isOpen, isHide} from '../_variables';

export default class HeaderScroll {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  headerscroll() {
    const header = document.querySelector('.c-header');
    if ($(window).scrollTop() >= 1) {
      header.classList.add('c-header--height');
    } else if ($(window).scrollTop() === 0) {
      header.classList.remove('c-header--height');
    }
  }

  init(){
    if(this.elm){
      window.addEventListener('scroll', (event) => {
        this.headerscroll();
      });
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      if (isIE11) {
        $('body').on('mousewheel', function(e){
          const header = document.querySelector('.c-header');
          if(e.originalEvent.wheelDelta > 0) {
            header.classList.add(isOpen);
            header.classList.remove(isHide);
          }
          else {
            header.classList.remove(isOpen);
            header.classList.add(isHide);
          }
        });
      } else {
        let lastScrollTop = 0;
        window.addEventListener("scroll", function(){
          let st = window.pageYOffset || document.documentElement.scrollTop;
          const header = document.querySelector('.c-header');
          if (st > lastScrollTop){
            header.classList.remove(isOpen);
            header.classList.add(isHide);
          } else {
            header.classList.add(isOpen);
            header.classList.remove(isHide);
          }
          lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
        });
      }
    }
  }
}