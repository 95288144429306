import $ from 'jquery';
import { parse } from 'querystring';

export default class historyProducts {
  constructor(props) {
    this.ele = document.querySelector(props.target);
  }

  init() {
    const params = parse(window.location.search.slice(1));
    if($('.c-tabs-btn').eq(Number(params['tab_num'])).length) {
      $('.c-tabs-btn').eq(Number(params['tab_num'])).trigger('click');
      $(window).scrollTop($('.p-container-history-products').offset().top);
    }
  }
}
