import $ from 'jquery';
import {isActive} from '../_variables';

export default class PageTopScroll {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  init(){
    if(this.elm){
      let oldTop = null;
      let newTop = 0;
      let height = 0;
      let doc = 0;
      let pos = 0;
      window.addEventListener('scroll', (e) => {
        newTop = $(window).scrollTop();
        doc = $(document).height();
        height = $(window).height();
        pos = doc - (height + 100);

        if ((newTop > oldTop || newTop == 0) && newTop < pos) {
          this.elm.classList.remove(isActive);
        } else {
          this.elm.classList.add(isActive);
        }
        oldTop = newTop;
      });
    }
  }
}