import $ from 'jquery';
import {isActive, isShow, isLocked} from '../_variables';

export default class Gnavisp {
  constructor(props) {
    this.elm = document.querySelectorAll(props.target);
  };

  init(){
    if(this.elm.length){
      const menuSp = document.querySelector('.gnavi');
      const header = document.querySelector('.c-header');
      const menuBtn = document.querySelector('.js-open-menu');
      const btnClose = document.querySelector('.js-close-menu');

      // open menu sp
        menuBtn.addEventListener('click', () => {
          menuBtn.classList.toggle(isActive);
          menuSp.classList.toggle(isShow);
          $('html').toggleClass(isLocked);
          header.classList.toggle(isLocked);
        });

      // close menu sp
        btnClose.addEventListener('click', (event) => {
          event.preventDefault();
          menuSp.classList.remove(isShow);
          menuBtn.classList.remove(isActive);
          header.classList.remove(isLocked);
          $('html').removeClass(isLocked);
        });
    }
  }
}