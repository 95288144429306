import $ from 'jquery';
import Slick from 'slick-carousel';

export default class FadeZoomSlider {
  constructor(props) {
    this.ele = document.querySelector(props.target);
  }

  init() {
    if (this.ele) {
      const isAnimated = 'is-animated';
      let $slider;

      $(this.ele).on('init', (ev, slick) => {
        slick.$slider.find('.slick-active').addClass(isAnimated);
      });
      
      $slider = $(this.ele).slick({
        autoplay: true,
        autoplaySpeed: 5000,
        speed: 1000,
        fade: true,
        dots: false,
        arrows: false,
        swipe: false,
        touchMove: false,
        pauseOnFocus: false,
        pauseOnHover: false,
        rows: 0,
      })
      .on('beforeChange', (ev, slick, currentSlide, nextSlide) => {
        slick.$slider
          .find(`[data-slick-index=${nextSlide}]`)
          .addClass(isAnimated);
      })
      .on('afterChange', (ev, slick, currentSlide) => {
        slick.$slider
          .find(`[data-slick-index!=${currentSlide}]`)
          .removeClass(isAnimated);
      });
    }
  }
}
