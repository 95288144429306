import $ from 'jquery';
import {isActive} from '../_variables';

const gnaviCurrent = () => {
  const $nav = $('.gnavi li');
  const firstPath = window.location.pathname.split('/')[1];
  const lastPath = '/' + firstPath + '/';

  $nav.each((index, element) => {
    const $this = $(element);
    const href = $this.find('a').attr('href');
    if (lastPath === href) {
      $this.find('a').addClass(isActive);
    }
  });
};

export default gnaviCurrent;