import $ from 'jquery';

export default class fadeScroll {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  fadeBox() {
    const itemElement = document.querySelectorAll(".js-fade-scroll .js-fade-scroll-item");
    let winTop = window.pageYOffset + ((window.innerHeight * 85) / 100);
    for (let i = 0; i < itemElement.length; i++) {
      const a = itemElement[i];
      const boxTop = a.getBoundingClientRect().top + window.pageYOffset;
      if (winTop >= boxTop) {
        if (!a.classList.contains('fadeInDown')) {
          a.classList.add('fadeInDown');
        }
      }
    }
  }

  init() {
    if (this.elm) {
      window.addEventListener('scroll', (event) => {
        event.preventDefault();
        this.fadeBox();
      });
    }
  }
}