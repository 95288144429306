import AnchorLink from './modules/anchorLink';
import Pagetop from './modules/pagetop';
import Gnavisp from './modules/gnavisp';
import Top from './modules/top';
import HeaderScroll from './modules/headerScroll';
import PageTopScroll from './modules/pagetopScroll';
import gnaviCurrent from './modules/gnaviCurrent';
import Tab from './modules/tab';
import MatchHeight from './modules/match_height';
import FadeZoomSlider from './modules/fadeZoomSlider';
import FadeScroll from './modules/fadeScroll';
import SideScrollFixed from './modules/SideScrollFixed';
import DeviceCheck from './modules/DeviceCheck';
import RecruitIndex from './modules/recruitIndex';
import BtnFixedEntry from './modules/btnFixedEntry';
import InstagramFactory from './modules/instagram_factory';
import historyProducts from './modules/history_products';

const anchorLink = new AnchorLink({
  target: '.js-anchor'
});

const pagetop = new Pagetop({
  target: '.js-page-top'
});

const gnavisp = new Gnavisp({
  target: '.js-open-menu'
});

const top = new Top({
  target: '#top'
});

const headerscroll = new HeaderScroll({
  target: '.js-header-scroll'
});

const pagetopscroll = new PageTopScroll({
  target: '.c-footer-page-top'
});

const tab = new Tab({
  target: '.js-tab-content'
});

const matchHeight = new MatchHeight({
  target: '.js-matchHeight-group'
});

const fadeZoomSlider = new FadeZoomSlider({
  target: '.js-fade-zoom-slider'
});

const fadeScroll = new FadeScroll({
  target: '.js-fade-scroll'
});

const sideScrollFixed = new SideScrollFixed({
  target: '.js-side-scroll-fixed'
});

const deviceCheck = new DeviceCheck({
  target: 'html'
});

const recruitIndex = new RecruitIndex({
  target: '#recruit-top'
});

const btnFixedEntry = new BtnFixedEntry({
  target: '.js-btn-fixed-entry'
});

const instagramFactory = new InstagramFactory({
  target: '#instagram-factory-feed',
  numberOfPhoto: 6
});

const history_Products = new historyProducts({
  target: 'html'
});

anchorLink.init();
pagetop.init();
gnavisp.init();
top.init();
headerscroll.init();
pagetopscroll.init();
tab.init();
matchHeight.init();
fadeZoomSlider.init();
fadeScroll.init();
sideScrollFixed.init();
deviceCheck.init();
recruitIndex.init();
btnFixedEntry.init();
instagramFactory.init();
history_Products.init();
gnaviCurrent();
