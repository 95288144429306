import $ from 'jquery';
export default class DeviceCheck {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  init() {
    if (this.elm) {
      window.addEventListener('load', (event) => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
        if(isTablet) {
          document.querySelector('meta[name="viewport"]').setAttribute("content", "width=1200,initial-scale=1.0,minimum-scale=1.0");
        }
      });
    }
  }
}