import $ from 'jquery';
export default class AnchorLink {
  constructor(props) {
    this.anchors = document.querySelectorAll(props.target);
  }

  init() {
    if (this.anchors.length) {
      for (let i = 0; i < this.anchors.length; i++) {
        this.anchors[i].addEventListener('click', (e) => {
          e.preventDefault();
          const self = e.currentTarget;
          const href = self.getAttribute('href');
          const posTarget = $(href).offset().top;
          $('body, html').stop().animate({
            scrollTop: posTarget
          }, 400, 'swing');
        });
      }
    }
  }
}