import {isActive} from '../_variables';

export default class Tab {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }


  init(){
    if(this.elm){
      const tabs = document.querySelector(".js-tab-content");
      const tabsBtns = tabs.querySelectorAll(".c-tabs-btn");
      const tabsContents = tabs.querySelectorAll(".c-tab-content");
      tabs.addEventListener("click", event => {
        for (let i = 0; i < tabsBtns.length; i++) {
          if (event.target === tabsBtns[i]) {
            removeCurrentTab();
            displayCurrentTab(i);
            break;
          }
        }
      });

      function displayCurrentTab(current) {
        for (let i = 0; i < tabsContents.length; i++) {
          tabsContents[current].classList.add(isActive);
          tabsBtns[current].classList.add(isActive);
        }
      }
      displayCurrentTab(0);

      function removeCurrentTab() {
        for (let i = 0; i < tabsContents.length; i++) {
          tabsContents[i].classList.remove(isActive);
          tabsBtns[i].classList.remove(isActive);
        }
      }
    }
  }
}