import $ from 'jquery';
import { breakpointMD } from '../_variables';

export default class SideScrollFixed {
  constructor(props) {
    this.$ele = $(props.target);
  }

  init() {
    if (this.$ele.length) {
      $(this.$ele).each((index, el) => {
        const $container = $(el);
        const $bg = $container.children();
        const fixedClass = 'is-fixed';
        const stopClass = 'is-stop';

        $(window).on('scroll', function () {
          const scrollTop = $(window).scrollTop();
          const startPos = $container.offset().top;

          if ($(window).innerWidth() <= breakpointMD) {
            const endPos = startPos + $bg.height();

            $bg
              .css({ width: '' })
              .removeClass(stopClass);

            if (scrollTop > endPos) {
              $bg.css({ visibility: 'hidden' });
            } else if (scrollTop >= startPos) {
              $bg
                .css({ visibility: '' })
                .addClass(fixedClass);
            } else if (scrollTop < endPos) {
              $bg
                .css({ visibility: '' })
                .removeClass(fixedClass);
            }
          } else {
            const endPos = ($container.height() + startPos) - $bg.height();

            if (scrollTop > startPos && scrollTop < endPos) {
              $bg
                .css({ width: $container.width(), visibility: '' })
                .removeClass(stopClass)
                .addClass(fixedClass);
            } else if (scrollTop > startPos && scrollTop >= endPos) {
              $bg
                .css({ width: '', visibility: '' })
                .removeClass(fixedClass)
                .addClass(stopClass);
            } else {
              $bg
                .css({ width: '', visibility: '' })
                .removeClass(fixedClass)
                .removeClass(stopClass);
            }
          }
        });

        $(window).on('resize', function () {
          if ($(window).innerWidth() <= breakpointMD || !$bg.hasClass(fixedClass)) {
            return;
          }

          $bg.css({ width: $container.width() });
        });
      });
    }
  }
}
