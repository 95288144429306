import $ from 'jquery';

export default class Top {
  constructor(props) {
    this.elm = document.querySelector(props.target);
  }

  scrollStop() {
    const bottleElmt = document.querySelector('.p-top-intro .img');
    const titleElmt = document.querySelector('.p-top-intro .inner .ttl');
    const heroElmt = document.querySelector('.p-hero-top');
    const introElmt = document.querySelector('.p-top-intro .inner');
    const newsElmt = document.querySelector('.p-top-news-highlight');
    const bgIntroElemt = document.querySelector('.p-top-intro');
    const boxElmt = document.querySelector('.p-top-intro .inner .in');
    const heroTop = heroElmt.offsetTop - 190;
    let introTop = introElmt.offsetTop + 184;
    if (newsElmt) {
      introTop = introElmt.offsetTop + 244;
    }
    const bgIntroTop = introElmt.offsetTop;
    if ($(window).scrollTop() > heroTop && $(window).scrollTop() < introTop) {
      bottleElmt.classList.add('scroll');
    } else {
      bottleElmt.classList.remove('scroll');
    }
    if ($(window).scrollTop() > introTop) {
      bottleElmt.classList.remove('scroll');
      bottleElmt.classList.add('finished');
    }
    if ($(window).scrollTop() > bgIntroTop) {
      titleElmt.classList.add('fade');
    }
    if ($(window).scrollTop() > bgIntroTop) {
      boxElmt.classList.add('fade');
    }
    if ($(window).scrollTop() > (bgIntroTop + 70)) {
      bgIntroElemt.classList.add('show-bg');
    }
  }

  animationTop() {
    const header = document.querySelector('.c-header .inner');
    const title = document.querySelector('.p-top-heading-lv1 .ttl');
    const lead = document.querySelector('.p-top-heading-lv1 .txt');
    const product = document.querySelector('.p-top-intro .img img');
    const blackground = document.querySelector('.p-hero-top-in');
    setTimeout(() => {
      title.classList.add('fade');
    }, 800);
    setTimeout(() => {
      lead.classList.add('fade');
    }, 1200);
    setTimeout(() => {
      product.classList.add('fade');
    }, 1800);
    setTimeout(() => {
      blackground.classList.add('fade');
    }, 2400);
    setTimeout(() => {
      header.classList.add('fade');
    }, 2400);
  }

  init() {
    if (this.elm) {
      this.animationTop();
      window.addEventListener('scroll', (event) => {
        event.preventDefault();
        this.scrollStop();
      });
    }
  }
}